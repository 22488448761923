.left{
    color:#6566CD ;
}

input{
    background-color:#F2F1F8 ;
}

textarea{
    background-color: #F2F1F8;
}

.button1{
    background-color: #6566CD;
}

.icon1{
    color: #6566CD;
    
}