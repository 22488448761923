

form{
    display: flex;
    flex-direction: column;
}

input{
    width: 300px;
    height: 50px;
    margin: 10px;
}

.button{
    width: 200px;
    height: 45px;
    background-color: coral;
    cursor: pointer;
    color: aliceblue;
}

.first{
    background-color: rgb(255, 226, 226);
    justify-content: center;
    align-items: center;
    margin: 5%;
    margin-top: 10%;
    padding: 2%;
    
    
}



@media only screen and (min-width: 600px) {
    .login {
            height: 80vh;
        }
}