.information{
    
        flex: 1;
        height: 50vh;
        border-radius: 20px;
        background-color: rgb(251, 250, 250);
        padding: 0%;
        margin-top: 1.5%;
}

.links:hover{
    border-right: 5px solid #7C8FE0;
}

.link_un{
    border-right: 5px solid #afbefe;
}

.categories{
    padding-top: 4%;
    
}

@media only screen and (max-width: 600px) {
    .information{
        flex: 0;
        border-radius: 0;
        
    }
}