.top{
    /* background-color: #bbbffc; */
    /* background-color: #F4D6BE; */
    background-color: #7C8FE0;
    margin: 2%;
    padding-bottom: 0%;
}

/* .top_cont{
    padding-bottom: 3%;
    padding-top: 3%;
} */