.tablechat{
    margin: 2%;
    
    padding: 4%;
   
}

.admin{
    height: 180vh;
}

@media only screen and (min-width: 600px) {
    .admin {
            height: auto;
        }
}