
.second{
padding-left: 8%;
padding-right: 8%;
padding-top: 1%;
padding-bottom: 3%;
}



@media only screen and (max-width: 600px) {
    .second {
           
            flex-direction: column;
            
        }
}