.form {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    

}

.data {
    background-color: #7C8FE0;
    border-radius: 10px;
    margin: 2%;
   
}

.input {
    background-color: #F2F1F8;
    
}

.new{
    width: 100%;
    padding: 2%;
}

.top{
    background-color: #fff;
}

.right{
    width: 100%;
    
}