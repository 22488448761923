.App{
    /* background-color: #fff4df; */
background-color: #F2F1F8;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-container {
    display: flex;
}

 main {
    
   flex: 1;
} 

.title {
    font-size: 3rem;
    display: grid;
    place-items: center;
}

/* Sidebar */
.sidebar {
    /* background: rgb(0, 7, 61); */
    background-color: #7286e1;
    color: white;
    height: auto;
    overflow-y: auto;
}

.top_section {
    display: flex;
    align-items: center;
    justify-content: s;
    padding: 15px 10px;
}

.logo {
    font-size: 20px;
    line-height: 0;
}

.bars {
    width: 40px;
}

.hide {
    display: none;
}


.search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
}

.search input {
    border: none;
    margin-left: 20px;
    border-radius: 5px;
    background: rgb(238, 238, 238);

    color: white;
}

.routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.link {
    display: flex;
    color: white;
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
    border-right: 4px solid white;
    background: rgb(187, 196, 255);
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
    border-right: 4px solid white;
    background: rgb(174, 183, 253);
   
}

.link_text {
    white-space: nowrap;
    font-size: 15px;
}

.menu {
    display: flex;
    color: white;

    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
}

.menu_item {
    display: flex;
    gap: 10px;
}

.menu_container {
    display: flex;
    flex-direction: column;
}

.menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
}

.icons{
    font-size: 100px;
}

@media screen and (min-width: 680px) {
    
}