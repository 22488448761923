.chategory {
    flex: 6;
    padding: 2%;
    border-radius: 20px;
    /* background-color: rgb(255, 254, 254); */
    background-color: #F2F1F8;
    height: auto;
        overflow-y: auto;
}

.categories{
    /* border: 3px solid rgb(211, 211, 211); */
    background-color: #fcfbff;
}
.data{
    height: auto;
        overflow-y: auto;
       
}

.heading{
    background-color:#7C8FE0 ;
}


@media only screen and (max-width: 600px) {
    .chategory {
        flex: 0;
        border-radius: 0;
    }
}