.featureschart{
    width: 100px;
    height: 100px;
}

.featured{
    flex: 2;
    background-color: rgb(249, 249, 249);
}

.bottom{
    padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
}

  .title {
      font-weight: 500;
      color: gray;
  }

  .amount {
      font-size: 30px;
  }

  .desc {
      font-weight: 300;
      font-size: 12px;
      color: gray;
      text-align: center;
  }

  .summary {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
  }

  .itemTitle {
      font-size: 14px;
      color: gray;
  }

  .itemResult {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 14px;

     
  }

   .positive {
       color: green;
   }

   .negative {
       color: red;
   }
   .title1{
    font-size: 16px;
    font-weight: 500;
        color: gray;
   }

   .title2{
    font-size: 30px;
        font-weight: 500;
        color: gray;
   }