.chategory{
    flex: 6;
        padding: 2%;
        border-radius: 20px;
        background-color: #FED89D;
}

@media only screen and (max-width: 600px) {
    .chategory {
        flex: 0;
        border-radius: 0;
    }
}