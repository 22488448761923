.list{
    flex: 2;
    background-color: #FED89D;
    height: 42vh;
}

.panel{
    flex: 6;
    
}

.post{
    background-color: #FED89D;
}

@media only screen and (max-width: 600px) {
    .list {
        
       height: 25vh;
    }


    .content{
        display: inline;
        
    }
}

